/**
 * Shareable constants that can be used in both client-side and server-side.
 */

export const CONSTANTS = {
  // Number of cards per page in search snippets list
  SNIPPETS_CARDS_PER_PAGE: 18,

  // Only endpoints that need obfuscation are listed here.
  // All these endpoints should also be listed in preact.config.js and firebase.json.
  ENDPOINT_POST_RATING: 'pnnpr41259',
  ENDPOINT_POST_FEEDBACK: 'pnnpf243413774892',
  ENDPOINT_GET_PAGE_DATA: 'pnngpd37291823791',
  ENDPOINT_SEARCH: 'pnns8evndhs9aq9we8hs',

  QUERY_PARAM_LOCALE: "l",
  QUERY_PARAM_SEARCH_QUERY: "q",
  QUERY_PARAM_PAGE_NUMBER: "page",
  QUERY_PARAM_SORT_OPTION: "so",
  QUERY_PARAM_FILTER_TAGS_IDS: "fti",
  QUERY_PARAM_PATH: "p",

  QUERY_PARAM_SORT_OPTION_VALUE_RELEVANCE: 'r',
  QUERY_PARAM_SORT_OPTION_VALUE_LATEST: 'l',
  QUERY_PARAM_SORT_OPTION_VALUE_SHORTEST_COOKING_TIME: 'sct',
  QUERY_PARAM_SORT_OPTION_VALUE_LONGEST_COOKING_TIME: 'lct',
  QUERY_PARAM_SORT_OPTION_VALUE_AZ: 'az',
  QUERY_PARAM_SORT_OPTION_VALUE_ZA: 'za',

  SSR_INFO_PLACEHOLDER_SCRIPT_ID: 'EaFoxAqxx8-UCBE2KTI4h',
  SSR_INFO_FETCH_DATA_FAILED: 'WVGmnuL0Bw-4z1kxOqj8y-mU8dbj4ss6-6lDSgPq4Su',
  SSR_INFO_REDIRECT_TO_CANONICAL_START: '9VtLh0byw9-RcbuTqzh4n-c8LbE1rgfC-MdmtNTEqA4',
  SSR_INFO_REDIRECT_TO_CANONICAL_END: 'qAOL9UJZId-cQ1HPBwT0M-gBT9pJHxF2-Rleriv5CYp',

  LOCALE_EN: 'en',
  LOCALE_ID: 'id',

  LANG_COOKIE_NAME: 'panomnom_lang',

  // Collection of assets URLs hosted in cloudinary (accessed through https://images.panomnom.com (cloudflare worker))
  //
  // Exceptions:
  // - Footer's banner is hardcoded in the CSS file.
  // - SeoDataCollector's SEO LOGO URL (since that file is shared between client and server).
  // 
  // How to create favicons / maskable icons:
  // - Get 800x800 square icon image, see assets/maskable_icon.png
  // - Go to https://maskable.app/editor, and upload the image
  // - Download all the maskable icons.
  // - Get 800x800 circular icon image
  // - Go to https://favicon.io to convert the image to favicon
  DEFAULT_RECIPE_BANNER: 'https://images.panomnom.com/upload/v1666442593/assets/Banner_RecipeCut_bs0mxx.png',
  DEFAULT_PANOMNOM_LOGO_URL: 'https://images.panomnom.com/upload/v1657702856/assets/top_logo_20220713_vghpsi.png',

  // Don't use this for Panomnom Image, since it has the `t_qo` whereas panomnom image needs the publicID starting from the version number.
  DEFAULT_PANOMNOM_LOGO_URL_OPTIMIZED: 'https://images.panomnom.com/upload/t_qo/v1657702856/assets/top_logo_20220713_vghpsi.png',
};

export const ALL_LOCALES = [CONSTANTS.LOCALE_EN, CONSTANTS.LOCALE_ID];