import { CloudinaryUtils } from './cloudinary-utils';
import { PageData } from './page-data';
import { PathsUtil } from './paths-util';
import { Blog, BlogContent, Locale, Recipe, RecipeDetail } from "./raw-data-types";
import { SeoData } from './seo-data';
import { Tag, TagGroup } from './tags';

const WEBSITE_DOMAIN = 'panomnom.com';
const DEFAULT_SEO_LOGO_URL = `https://images.panomnom.com/upload/v1657715165/assets/maskable_icon_x512_20220713_t2e9to.png`;
const DEFAULT_BLOG_TITLE = '<<0>> | Panomnom';
const DEFAULT_TITLE_ID = 'Kumpulan Resep Makanan yang Enak dan Mudah Dibuat | Panomnom';
const DEFAULT_TITLE_EN = 'Tasty and Easy-to-make Food Recipes | Panomnom';
const DEFAULT_DESCRIPTION_ID = 'Ingin cara memasak yang mudah diikuti? Temukan resep masakan di Panomnom, beserta dengan cara membuat yang mudah diikuti oleh siapapun.';
const DEFAULT_DESCRIPTION_EN = "Do you want a cooking guide that's easy to follow? Find recipes on Panomnom, complete with step-by-step guides that are easy for anyone to follow.";
const RECIPE_TITLE_ID = 'Resep <<0>> Enak dan Mudah | Panomnom';
const RECIPE_TITLE_EN = 'Tasty and Easy <<0>> Recipe | Panomnom';
const RECIPE_TITLE_GENERATED_CONTENT = '<<0>> | Panomnom';
const GENERAL_PREFIX_ID = {
  aboutUs: 'Tentang Kami',
  blogs: 'Blogs',
  privacyPolicy: 'Kebijakan Privasi',
  search: 'Cari',
};
const GENERAL_PREFIX_EN = {
  aboutUs: 'About Us',
  blogs: 'Blogs',
  privacyPolicy: 'Privacy Policy',
  search: 'Search',
};

export class SeoDataCollector {
  /** Collects seo data for specified locale and path. */
  static collectSeoData(locale: Locale, path: string, pageData: PageData): SeoData {
    if (PathsUtil.isRecipePath(path)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return SeoDataCollector.collectForRecipePage(locale, path, pageData.tagGroups!, pageData.mainRecipe!, pageData.mainRecipeDetail!, !!pageData.isGeneratedContent);
    }

    if (PathsUtil.isBlogPath(path)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return SeoDataCollector.collectForBlogPage(locale, path, pageData.tagGroups!, pageData.mainBlog!, pageData.mainBlogContent!);
    }

    return SeoDataCollector.collectDefault(locale, path);
  }

  private static collectForBlogPage(locale: Locale, path: string, tagGroups: TagGroup[], blog: Blog, blogContent: BlogContent): SeoData {
    // Blog not found, fallback to default
    if (!blog || !blog.docId || !blogContent || !blogContent.docId) {
      return SeoDataCollector.collectDefault(locale, path);
    }

    const contentSeoTitle = (blog.contentSeo?.metaTitle || '').trim();
    const contentSeoDescription = (blog.contentSeo?.metaDescription || '').trim();

    const title = contentSeoTitle ? `${contentSeoTitle} | Panomnom` : DEFAULT_BLOG_TITLE.replace('<<0>>', blog.title);
    const description = contentSeoDescription ? contentSeoDescription : (blogContent.content || '').substring(0, 160).replace(/<.*?>/g, '');

    return {
      title,
      description,
      canonicalUrl: SeoDataCollector.getCanonicalUrl(path),
      ...SeoDataCollector.gatherOgTags(title, description, locale, path, blog.imageUrl),
      ...SeoDataCollector.gatherTwitterTags(title, description),
      ...SeoDataCollector.gatherRichResults(title, description, locale, tagGroups, [], undefined, blog),
    };
  };

  private static collectForRecipePage(locale: Locale, path: string, tagGroups: TagGroup[], recipe: Recipe, recipeDetail: RecipeDetail, isGeneratedContent: boolean): SeoData {
    // Recipe not found, fallback to default
    if (!recipe || !recipe.docId) {
      return SeoDataCollector.collectDefault(locale, path);
    }

    const contentSeoDescription = (recipe.contentSeo?.metaDescription || '').trim();
    const contentSeoTitle = (recipe.contentSeo?.metaTitle || '').trim();

    let title: string;
    if (isGeneratedContent) {
      // Use the programmatically generated title
      title = RECIPE_TITLE_GENERATED_CONTENT.replace('<<0>>', recipe.title);
    } else {
      title = contentSeoTitle ?
        contentSeoTitle : locale === 'id' ?
          RECIPE_TITLE_ID.replace('<<0>>', recipe.title) :
          RECIPE_TITLE_EN.replace('<<0>>', recipe.title);
    }

    const description = contentSeoDescription ?
      contentSeoDescription : locale === 'id' ?
        `${recipe.quoteText} - ${DEFAULT_DESCRIPTION_ID}` :
        `${recipe.quoteText} - ${DEFAULT_DESCRIPTION_EN}`;

    return {
      title,
      description,
      canonicalUrl: SeoDataCollector.getCanonicalUrl(path),
      ...SeoDataCollector.gatherOgTags(title, description, locale, path, recipe.imageUrl),
      ...SeoDataCollector.gatherTwitterTags(title, description),
      ...SeoDataCollector.gatherRichResults(title, description, locale, tagGroups, [recipe], recipeDetail),
    };
  };

  private static collectDefault(locale: Locale, path: string): SeoData {
    let title = locale === 'id' ? DEFAULT_TITLE_ID : DEFAULT_TITLE_EN;
    let description = locale === 'id' ? DEFAULT_DESCRIPTION_ID : DEFAULT_DESCRIPTION_EN;
    const generalPrefix = locale === 'id' ? GENERAL_PREFIX_ID : GENERAL_PREFIX_EN;

    // This method is also used as "default" for other pages.
    // Append some prefix to avoid duplicated title / description.
    if (PathsUtil.isAboutUsPath(path)) {
      title = `${generalPrefix.aboutUs} | ${title}`;
      description = `${generalPrefix.aboutUs} | ${description}`;
    } else if (PathsUtil.isBlogsPath(path)) {
      title = `${generalPrefix.blogs} | ${title}`;
      description = `${generalPrefix.blogs} | ${description}`;
    } else if (PathsUtil.isPrivacyPolicyPath(path)) {
      title = `${generalPrefix.privacyPolicy} | ${title}`;
      description = `${generalPrefix.privacyPolicy} | ${description}`;
    } else if (PathsUtil.isSearchPath(path)) {
      title = `${generalPrefix.search} | ${title}`;
      description = `${generalPrefix.search} | ${description}`;
    }

    return {
      title,
      description,
      canonicalUrl: SeoDataCollector.getCanonicalUrl(path),
      ...SeoDataCollector.gatherOgTags(title, description, locale, path, DEFAULT_SEO_LOGO_URL),
      ...SeoDataCollector.gatherTwitterTags(title, description),
      ...SeoDataCollector.gatherRichResults(title, description, locale),
    };
  };

  /** Converts an image URL to use panomnom images proxy URL. */
  private static convertImageUrlToPanomnomImageUrl(imageUrl: string): string {
    if (!imageUrl) {
      return imageUrl;
    }

    const assetProperties = CloudinaryUtils.parseUrl(new URL(imageUrl));
    const panomnomImageUrl = CloudinaryUtils.constructUrl(assetProperties, {
      [CloudinaryUtils.getBuildVersionQueryParam()]: '2',
      [CloudinaryUtils.getHeightQueryParam()]: '960', // common 4:3 dimension ratio for seo, 1200px is the recommended minimum width, so 960px height is 1280px width.
    });

    return panomnomImageUrl;
  };

  private static getTag(rawTagGroups: TagGroup[], tagGroupId: string, tagId: string): Tag | null {
    const tagGroup = rawTagGroups.find(rtg => rtg.docId === tagGroupId);
    if (!tagGroup) {
      return null;
    }

    const tag = (tagGroup.tags || []).find(t => t.id === tagId);
    if (!tag) {
      return null;
    }

    return tag;
  }

  private static gatherRichResults(title: string, description: string, locale: string, tagGroups: TagGroup[] = [], recipes: Recipe[] = [], recipeDetail?: RecipeDetail, blog?: Blog) {
    const richResultsContext = 'https://schema.org/';

    const categoryRichResults: any[] = [];
    if (recipes && recipes.length) {
      // https://developers.google.com/search/docs/advanced/structured-data/carousel
      categoryRichResults.push({
        '@context': richResultsContext,
        '@type': 'ItemList',
        itemListElement: recipes.map((r, idx) => ({
          '@type': 'ListItem',
          position: idx + 1,
          url: `https://${WEBSITE_DOMAIN}${PathsUtil.getRecipePath(locale, r.slug, r.userVisibleId)}`,
        })),
      });
    }

    const recipeRichResults: any[] = [];
    const recipe = recipes.find(r => r.docId === recipeDetail?.recipeDocId);
    if (recipe && recipe.docId) {
      let aggregateRatingObj = {};
      if ((recipe.rating || {}).count) {
        const ratingValue = (Math.round(recipe.rating.sum / recipe.rating.count * 10) / 10).toFixed(1);
        const ratingCount = recipe.rating.count;
        aggregateRatingObj = {
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue,
            ratingCount,
            bestRating: '5',
            worstRating: '1',
          },
        };
      }

      // Process youtube URL if it's a known embed URL.
      const videoUrl =
        (recipe.youtubeUrl?.includes('youtube.com') && recipe.youtubeUrl?.includes('/embed/')) ?
          `https://www.youtube.com/watch?v=${recipe.youtubeUrl!.split('/').pop()}` : null;
      const videoEmbedUrl = recipe.youtubeUrl; // Assume it's always embeddable.
      let videoObj = {};
      if (videoUrl && videoEmbedUrl) {
        videoObj = {
          video: {
            '@type': 'VideoObject',
            name: recipe.contentSeo?.metaTitle || recipe.title,
            description: recipe.contentSeo?.metaDescription || recipe.quoteText,
            contentUrl: videoUrl,
            embedUrl: videoEmbedUrl,
          },
        };
      }

      // https://developers.google.com/search/docs/advanced/structured-data/recipe
      recipeRichResults.push({
        '@context': richResultsContext,
        '@type': 'Recipe',
        name: recipe.contentSeo?.metaTitle || recipe.title,
        image: [SeoDataCollector.convertImageUrlToPanomnomImageUrl(recipe.imageUrl)],
        author: {
          '@type': 'Person',
          name: 'Elfie',
          url: `https://${WEBSITE_DOMAIN}`,
        },
        ...videoObj,
        description: recipe.contentSeo?.metaDescription || recipe.quoteText,
        totalTime: `PT${recipe.timeMinutes}M`,
        recipeYield: recipe.yield,
        recipeCategory: (recipe.tags || []).map(rt => this.getTag(tagGroups, rt.tagGroupId, rt.tagId)?.text).filter(t => t).join(', '),
        recipeIngredient: recipeDetail?.ingredients.map(ing => ing.items).reduce((prev, cur) => [...prev, ...cur]),
        recipeInstructions: recipeDetail?.steps.map(step => ({
          '@type': 'HowToStep',
          text: step.text,
          image: SeoDataCollector.convertImageUrlToPanomnomImageUrl(step.image),
        })),
        ...aggregateRatingObj,
      });
    }

    const blogRichResults: any[] = [];
    if (blog && blog.docId) {
      blogRichResults.push({
        '@context': richResultsContext,
        '@type': 'BlogPosting',
        headline: blog.title,
        image: [SeoDataCollector.convertImageUrlToPanomnomImageUrl(blog.imageUrl)],
        author: {
          '@type': 'Person',
          name: 'Elfie',
          url: `https://${WEBSITE_DOMAIN}`,
        },
      });
    }

    return {
      richResults: [
        // https://developers.google.com/search/docs/advanced/structured-data/sitelinks-searchbox
        {
          '@id': `https://${WEBSITE_DOMAIN}/#website`,
          '@context': richResultsContext,
          '@type': 'WebSite',
          description,
          inLanguage: locale === 'id' ? 'id-ID' : 'en-US',
          url: `https://${WEBSITE_DOMAIN}/${locale}/`,
          name: title,
          potentialAction: {
            '@type': 'SearchAction',
            'query-input': 'required name=search_query',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: `https://${WEBSITE_DOMAIN}/${locale}/search?q={search_query}`,
            },
          },
          publisher: {
            '@id': `https://${WEBSITE_DOMAIN}/#organization`,
            url: `https://${WEBSITE_DOMAIN}/${locale}/`,
          },
        },
        // https://developers.google.com/search/docs/advanced/structured-data/logo
        {
          '@id': `https://${WEBSITE_DOMAIN}/#organization`,
          '@type': 'Organization',
          name: 'Panomnom',
          url: `https://${WEBSITE_DOMAIN}/`,
        },
        ...categoryRichResults,
        ...recipeRichResults,
        ...blogRichResults,
      ],
    };
  };

  private static gatherOgTags(title: string, description: string, locale: Locale, path: string, imageUrl: string) {
    return {
      ogLocale: locale === 'id' ? 'id_id' : 'en_us',
      ogSiteName: title,
      ogType: 'website',
      ogTitle: title,
      ogDescription: description,
      ogUrl: SeoDataCollector.getCanonicalUrl(path),
      ogImage: SeoDataCollector.convertImageUrlToPanomnomImageUrl(imageUrl),
    };
  };

  private static gatherTwitterTags(title: string, description: string) {
    return {
      twitterCard: 'summary',
      twitterDomain: WEBSITE_DOMAIN,
      twitterTitle: title,
      twitterDescription: description,
    };
  };

  private static getCanonicalUrl(path: string): string {
    let pathWithSlashPrefix = (path || '').startsWith('/') ? (path || '') : `/${path || ''}`;

    // remove query parameters if any
    if (pathWithSlashPrefix.indexOf('?') !== -1) {
      pathWithSlashPrefix = pathWithSlashPrefix.substring(0, pathWithSlashPrefix.indexOf('?'));
    }

    // remove hash if any
    if (pathWithSlashPrefix.indexOf('#') !== -1) {
      pathWithSlashPrefix = pathWithSlashPrefix.substring(0, pathWithSlashPrefix.indexOf('#'));
    }

    return `https://${WEBSITE_DOMAIN}${pathWithSlashPrefix}`;
  };
}